function MsgBlock({data}) {

    let bgColor = data.bgColor ? data.bgColor : "linear-gradient(45deg, rgba(150,150,150,1) 0%, rgba(0,0,0,1) 100%);";
    const time = new Date(data.time)

    const getTimeString = (time) => {
        let result = time.toLocaleTimeString().substring(0,5)
        return result[4] == ":" ? result.substring(0,4) : result
    }

    return ( 
    <div className="msgCard column" style={{background: bgColor}}>
        <div className="row">
            <h2>{data.authorName}</h2>
            <p>{getTimeString(time)}</p>
            </div>
        <p style={{wordWrap: "break-word"}}>{data.msg}</p>
    </div>
     );
}

export default MsgBlock;
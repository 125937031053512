import { Link } from "react-router-dom";
import Icon from "./Icon";
import Cookies from "universal-cookie";

function MainMenu() {

  const cookies = new Cookies();

  return (
    <div className="mainMenu">
      <Link to="/">
        <div className="menuItem centerAlign row">
          <Icon iconName={"list-sharp"} size="2vw" />
          <p className="">Úlohy</p>
        </div>
      </Link>
      <Link to="/teams">
        <div className="menuItem centerAlign row">
          <Icon iconName={"people-sharp"} size="2vw" />
          <p>Týmy</p>
        </div>
      </Link>
      <Link to="/chat">
        <div className="menuItem centerAlign row">
          <Icon iconName={"chatbox-ellipses-sharp"} size="2vw" />
          <p>Chat</p>
        </div>
      </Link>
      <div className="last">
        <div className="menuItem centerAlign row last logout" style={{width: "0vw"}} onClick={() => {cookies.set("identifier", "", { path: "/" });window.location.reload()}}>
          <Icon iconName={"log-out-sharp"} size="2vw" />
        </div>
      <Link to="/settings">
        <div className="menuItem centerAlign row last">
          <Icon iconName={"settings-sharp"} size="2vw" />
          <p>Nastavení</p>
        </div>
      </Link>
      </div>
    </div>
  );
}

export default MainMenu;

import { useEffect, useState } from "react";
import { login } from "../scripts/dataHandler";

interface Props {
    loginSetter: Function,
    cookieSetter: Function
}

function LoginPage({loginSetter, cookieSetter}: Props) {

    let [username,setUsername] = useState("")
    let [password,setPassword] = useState("")
    let [msg,setMsg] = useState("")
    let [waiting,setWaiting] = useState(false)

    const logger = async () => {
        setWaiting(true)
        login(username,password, loginSetter, cookieSetter, setMsg,setWaiting)
    }

    useEffect(() => {
        onkeyup = (e) => {if (e.key === "Enter") {logger()}};
    })

    return ( 
        <div className="container centerAlign column" >
            <h1>Login</h1>

            <h2>Přihlašovací jméno</h2>
            <input type="text" id="username" onInput={(e: any) => setUsername(e.target.value)}/>

            <h2>Heslo</h2>
            <input type="password" id="password" onInput={(e: any) => setPassword(e.target.value)}/>
            <button id="enter" onClick={logger} style={{marginTop: "3vh"}} className={waiting ? "waiting" : ""}>Enter</button>
            <h3>{msg}</h3>
            <h3><a href="https://smoulasoutez.cz/zmenaHeslaZadost" target="_blank" rel="noreferrer">Zapomenuté heslo</a></h3>
        </div>
     );
}

export default LoginPage;
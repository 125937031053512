import { Link } from "react-router-dom";

interface Props {
    team: any,
    pos: number
}

function TeamCard({team, pos}: Props) {

    //const animDelay = ((pos-1) * 0.05) + "s"
    const animDelay = (((pos-1)-(pos-1)*0.3) * 0.05) + "s" 
    let bgColor = "rgba(255,255,255, 0.7)"

    if (pos == 1) {
      bgColor = "linear-gradient(45deg, rgba(223,189,105,1) 0%, rgba(146,111,52,1) 100%)";
    } else if (pos == 2) {
      //bgColor = "linear-gradient(45deg, rgba(233,233,233,1) 22.8%, rgba(238,238,238,1) 42.4%, rgba(233,233,233,1) 62.98%, rgba(94,94,94,1) 80%);"
      bgColor = "linear-gradient(45deg, rgba(217,217,217,1) 28%, rgba(119,119,119,1) 100%)"
    } else if (pos == 3) {
      bgColor = "linear-gradient(45deg, #db6c2b, #673208)"
    }
    
  return (
    <Link to={"/teaminfo?team=" + team.name}>
    <div className="teamCard row" style={{animationDelay: animDelay, background: bgColor, textDecoration: "none"}}>
        <h2 style={{margin: "0vw"}}>{"#" + pos}</h2>
      <img
        className="pfp"
        src={team.pfp}
        alt={"Ikona týmu " + team.name}
      />
      <div className="column">
        <h1 style={{margin: "0vw"}}>{team.name}</h1>
        <h2 style={{margin: "0vw"}}>{team.points + " bodů"}</h2>
      </div>
    </div>
    </Link>
  );
}

export default TeamCard;

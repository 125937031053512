import TeamCard from "../../components/teamListPage/TeamCard";

interface Props {
    teams: any[]
}

function TeamListPage({teams}: Props) {


    const mapTeamCards = (teamData: any) => {

        if (teamData.length == 0) {
            return <h2>Přístup k týmům je momentálně uzamčen</h2>
        }

        let i = 0;

        /*let teamIds: any[] = []

        teamData = teamData.filter((e: any) => {if (teamIds.includes(e.id)) {return false} else {teamIds.push(e.id);return true}})
        teamData.sort((a: any, b: any) => b.points - a.points)*/
        return <div className="teamList">{teamData.map((team: any) => {
            i+=1;
            return <TeamCard team={team} pos={i} key={i}/>
        })}</div>
    }

    return ( <div className="centerAlign column" style={{marginTop: "4vh"}}>
        <h1>Žebříček týmů</h1>
        
        {mapTeamCards(teams)}
    </div> );
}

export default TeamListPage;
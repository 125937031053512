import { Route, Routes, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import "../styles/gamePage.css";

import MainMenu from "../components/general/MainMenu";

import ProblemTree from "./inGame/ProblemTree";
import SettingsPage from "./inGame/SettingsPage";
import TeamListPage from "./inGame/TeamListPage";
import TeamInfoPage from "./inGame/TeamInfoPage";
import { getAchievements, getChatMsgs, getProblems, getTeamAchievements, getTeams, checkToken, getAnswers } from "../scripts/dataHandler";
import ChatPage from "./inGame/ChatPage";
import AnswerHistory from "./inGame/AnswerHistory";

interface Props {
  token: string
}

function GamePage({token}: Props) {

  const [searchParams, setSearchParams] = useSearchParams();
  
  let [problems, setProblems] = useState([])
  let [teams, setTeams] = useState([])
  let [achievements, setAchievements] = useState([])
  let [teamAchievements, setTeamAchievements] = useState([])
  let [chatMsgs, setChatMsgs] = useState([])
  let [loggedTeamData, setLoggedTeamData] = useState(null)
  let [answers, setAnswers] = useState([])

  useEffect(() => {
    checkToken(token, setLoggedTeamData)
    setProblems(JSON.parse(localStorage.getItem("problems") || "[]"))
    getProblems(token,setProblems,false)
    getTeams(setTeams)
    getAchievements(setAchievements)
    getTeamAchievements(setTeamAchievements)
    getChatMsgs(setChatMsgs)
    getAnswers(token, setAnswers)
  }, [token])

  return (
    <div
      className="centerAlign column"
      style={{ width: "100vw", overflow: "hidden" }}
    >
      <div className="gameContainer" style={{}}>
      
        <Routes>
          <Route path="/" element={<ProblemTree problems={problems}/>} />
          <Route path="/teams" element={<TeamListPage teams={teams}/>} />
          <Route path="/teaminfo" element={<TeamInfoPage teamName={searchParams.get("team") || ""} teams={teams} achievements={achievements} teamAchievements={teamAchievements}/>} />
          <Route path="/chat" element={<ChatPage token={token} chatMsgs={chatMsgs}/>}/>
          <Route path="/settings" element={loggedTeamData ? <SettingsPage data={loggedTeamData} token={token}/> : <></>} />
          <Route path="/answers" element={<AnswerHistory answers={answers}/>}/>
        </Routes>
      
      </div>
      

      <MainMenu />
    </div>
  );
}

export default GamePage;

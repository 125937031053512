import { useState } from "react";
import { sendTeamDetails } from "../../scripts/dataHandler";

function SettingsPage({ data, token }) {
  let [inputData, setInputData] = useState({
    motto: data.motto,
    email: data.email,
    pfp: data.pfp
  });
  let [msg, setMsg] = useState("");

  const handleChange = (e) => {
    let temp = inputData;

    if (e.target.id === "pfp") {
      document.getElementById("teamImg").src = e.target.value
    }

    temp[e.target.id] = e.target.value;
    setInputData(temp);
  };

  const readURL = () => {
    const input = document.getElementById("teamPfpSelector");
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        console.log(e.target.result);
        document.getElementById("teamPfp").src = e.target.result;
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  /*const handleMemberChange = (e,i) => {
    let temp = inputData
    temp.teamMembers[i-1][e.target.id] = e.target.value
    setInputData(temp)
  }

  const mapMembers = (data) => {
    let i = 0;
    return data.members.map((m) => {
      i++;
      return (
        <div className="column">
          <h2 style={{ fontSize: "1.5vw" }}>{`Člen ${i} - ${m.name}`}</h2>

          <div style={{ marginLeft: "1vw" }}>
            <h2 style={{ margin: 0, fontSize: "1.5vw" }}>
              Jméno: <small>(max 100 znaků)</small>
            </h2>
            <input
              type="text"
              style={{ width: "30vw" }}
              maxLength={100}
              defaultValue={data.motto}
              id="motto"
              onChange={(e) => {handleMemberChange(e,i)}}
            />
          </div>
        </div>
      );
    });
  };*/

  return (
    <div className="centerAlign">
      <div className="teamSettings column">
        <h1>Údaje týmu</h1>

        <div className="row centerAlign">
          <div
            className="column centerAlign"
            style={{ textAlign: "center", marginRight: "2vw" }}
          >
            <img src={inputData.pfp} alt={"Ikona vašeho týmu"} id="teamImg" />
          </div>

          <div className="column">
            <h2 style={{ margin: "1vw 0vw", fontSize: "3vw" }}>{data.name}</h2>

            <h2 style={{ margin: 0 }}>Email:</h2>
            <input
              type="email"
              style={{ width: "25vw" }}
              maxLength={50}
              defaultValue={data.email}
              id="email"
              onChange={(e) => handleChange(e)}
            />

            <h2 style={{ margin: 0 }}>
              Motto: <small>(max 100 znaků)</small>
            </h2>
            <input
              type="text"
              style={{ width: "30vw" }}
              maxLength={100}
              defaultValue={data.motto}
              id="motto"
              onChange={(e) => handleChange(e)}
            />

            <h2 style={{ margin: 0 }}>Obrázek:</h2>
            <input
              type="text"
              style={{ width: "25vw" }}
              maxLength={255}
              defaultValue={data.pfp}
              id="pfp"
              onChange={(e) => handleChange(e)}
            />

            <div className="row" style={{ marginTop: "2vh" }}>
              <button onClick={() => {sendTeamDetails(inputData, token, setMsg)}}>
                Potvrdit
              </button>
            </div>
            <p>{msg}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;

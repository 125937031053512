function AnswerHistory({answers}) {

    const mapAnswers = (answers) => {
        return answers.map(a => 
            <div style={{margin: "1vw 0vw"}}>
                <h2>{`Úloha ${a.problemId}: ${a.answer}`}</h2>
            </div>
        )
    }

    return ( 
    <div className="centerAlign column" style={{minHeight: "100vh", width: "100vw"}}>
        <h1>Historie odpovědí</h1>
        <div className="column">
            {answers && mapAnswers(answers)}
        </div>
    </div> 
    );
}

export default AnswerHistory;
/*interface Props {
    teamName: string,
    teams: any[]
}*/

import AchievementIcon from "../../components/teamInfoPage/AchievementIcon";
import AchievementIconGhost from "../../components/teamInfoPage/AchievementIconGhost";

function TeamInfoPage({teams,teamName,achievements,teamAchievements}) {

    const getTeam = (name) => {
        return teams.filter((team) => {
            return team.name === name
        })
    }

    let [team] = getTeam(teamName)
    if (!team) {
      team = {}
    }

    let achieved = teamAchievements.filter((x) => x.teamName === teamName).map(x => x.achievementId)

    const mapAchievements = (achievements) => {

        if (team.name == "Duch") {
          return mapGhostAchievements()
        }

        let result = []
        for (let i = 0; i <= Math.floor(achievements.length / 8); i+=1) {
          let x = 0
          result.push(<div className="row" style={{marginTop: "1vh"}}>
            {achievements.slice(i*8,(i + 1) * 8 <= achievements.length ? (i + 1) * 8 : achievements.length)
              .map((achievement) => {
                  x+=1
                  return <AchievementIcon achievement={achievement} active={achieved.includes(achievement.id)} key={x}/>
              })}
          </div>)
        }
        return result

    }

    const mapGhostAchievements = () => {
      const ghostAchievement = {
        title: "###",
        desc: "xxxx",
        img: "https://i.imgur.com/RMbfW0Z.png"
      }

      const map = [
                  [1,1,1,1,1,1,1,0,0,0,0,1,1,0,0,0,0,0,1,1,1,1,1,1,1],
                  [1,0,0,0,0,0,1,0,0,1,0,0,0,0,0,1,0,0,1,0,0,0,0,0,1],
                  [1,0,1,1,1,0,1,0,1,0,1,1,0,1,1,1,0,0,1,0,1,1,1,0,1],
                  [1,0,1,1,1,0,1,0,0,1,0,1,1,0,1,1,0,0,1,0,1,1,1,0,1],
                  [1,0,1,1,1,0,1,0,0,1,1,1,0,0,0,1,1,0,1,0,1,1,1,0,1],
                  [1,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,1],
                  [1,1,1,1,1,1,1,0,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,1],
                  [0,0,0,0,0,0,0,0,1,0,0,1,1,1,1,0,1,0,0,0,0,0,0,0,0],
                  [1,1,1,0,1,1,1,1,1,0,0,0,0,0,0,0,1,1,1,0,0,0,1,0,0],
                  [0,1,0,0,1,0,0,1,1,1,0,1,1,1,0,0,1,0,1,0,1,0,0,1,0],
                  [1,1,1,1,1,0,1,1,0,0,1,1,1,0,0,1,1,1,1,0,0,1,0,0,0],
                  [0,1,1,1,0,1,0,0,1,1,1,1,1,1,0,0,0,0,1,1,0,0,0,1,1],
                  [1,1,1,1,0,0,1,0,1,0,1,0,0,0,0,0,0,0,0,1,0,0,1,1,1],
                  [0,0,0,1,1,0,0,0,1,1,1,1,1,1,0,1,1,0,0,0,1,0,0,1,0],
                  [1,0,0,0,1,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,1,0,1,0],
                  [0,1,0,1,1,0,0,1,0,1,1,0,1,1,1,0,1,1,1,1,0,0,0,0,0],
                  [1,0,0,0,1,1,1,1,0,1,1,0,1,0,1,0,1,1,1,1,1,0,1,0,1],
                  [0,0,0,0,0,0,0,0,1,1,0,0,1,1,1,1,1,0,0,0,1,0,0,1,0],
                  [1,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,1,0,1,0,1,1,0,1,1],
                  [1,0,0,0,0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,1,1],
                  [1,0,1,1,1,0,1,0,1,0,0,0,0,1,0,0,1,1,1,1,1,0,1,0,0],
                  [1,0,1,1,1,0,1,0,0,1,1,0,0,1,0,0,1,0,1,1,1,0,0,0,0],
                  [1,0,1,1,1,0,1,0,1,0,0,0,1,0,0,1,1,1,0,0,0,1,0,0,1],
                  [1,0,0,0,0,0,1,0,1,1,1,1,1,1,0,0,0,0,0,0,1,1,0,0,1],
                  [1,1,1,1,1,1,1,0,1,0,1,0,0,0,0,1,1,1,0,0,1,1,0,1,1],
                  ]
        let i = 0
        return <div className="column">{map.map(row => {
          return <div className="row">{row.map(x => {
            i++;
            return <AchievementIconGhost achievement={ghostAchievement} active={x} key={i}/>
          })}</div>
        })}</div>
    }

  return (
    <div className="centerAlign column">
        <div className="teamInfo">
      <div className="row">
      <img src={team.pfp} className="pfp" alt={"Ikona týmu " + team.name}/>
        <div className="column" style={{marginLeft: "3vw", marginTop: "6vh"}}>
            <h1>{team.name}</h1>
            <h2 style={{maxWidth: "50vw", wordWrap: "break-word"}}><i>{team.motto && `"` + team.motto + `"`}</i></h2>
        </div>
      </div>
      <h2>Achievements:</h2>
      <div>
        {mapAchievements(achievements)}
      </div>
      </div>
    </div>
  );
}

export default TeamInfoPage;

import { useState, useEffect } from "react";
import Icon from "../general/Icon";
import { checkAnswer } from "../../scripts/dataHandler";
import Cookies from "universal-cookie";

function ProblemModal({ problem, setVisible }) {

  const cookies = new Cookies();

  let [input, setInput] = useState("");
  let [msg, setMsg] = useState("")
  let [waiting,setWaiting] = useState(false)

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    onkeyup = (e) => {if (e.key === "Enter") {submit()}};
})

useEffect(() => {
  window.scrollTo(0,0)
}, [])

const submit = async () => {
  setWaiting(true)
  await checkAnswer(problem.id,input,cookies.get("identifier"),setVisible,setMsg,setWaiting)
}

  return (
    <div className="problemModal">
      <div
        className="bg"
        onClick={() => {
          window.scrollTo(0,0)
          setVisible(false);
        }}
      ></div>
      <div className="content column centerAlign">
        <div
          style={{ position: "absolute", top: "1vw", left: "1vw" }}
          onClick={() => {
            setVisible(false);
          }}
        >
          <Icon iconName={"close-sharp"} size="3vw" />
        </div>
        <h2>{problem.id + ": " + problem.title}</h2>
        <div style={{textAlign: "center",width: "50vw", textWrap: "wrap",wordBreak: "break-word"}}>
        <h3 dangerouslySetInnerHTML={{__html:problem.description}}></h3>
        </div>
        {problem.img && (
          <img src={problem.img} alt={"Obrázek pro úlohu " + problem.title} style={{maxHeight: "60vh"}}/>
        )}
        <h3>{problem.note}</h3>
        <input onChange={handleChange} value={input} type="text" maxLength={25}/>
        <h3>{problem.wrongAnsMultiplier ? `Počet záporných bodů za špatnou odpověď: ${problem.wrongAnsMultiplier * (4**problem.layer)}` : ""}</h3>
        <h3>{msg}</h3>
        <br /> <br /> <br /> <br /> <br />
        <div style={{ position: "absolute", bottom: "min(3vh,2vw)" }}>
          <button onClick={() => {submit()}} className={waiting ? "waiting" : ""} active={!waiting}>Odeslat</button>
        </div>
      </div>
    </div>
  );
}

export default ProblemModal;
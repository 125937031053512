import "../../styles/gamePage.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Problem } from "../../assets/mockData/problems";

import TreeNode from "../../components/problemPage/TreeNode";
import Line from "../../components/problemPage/Line";
import ProblemModal from "../../components/problemPage/ProblemModal";

interface Props {
  problems: any;
}

function ProblemTree({ problems }: Props) {
  let [problemModalVisible, setProblemModalVisible] = useState(false);
  let [selectedProblem, setSelectedProblem] = useState(problems[1]);

  useEffect(() => {
    window.scrollTo(0,0)
  })

  const treeNodeOnClick = (problem: Problem) => {
    setSelectedProblem(problem);
    setProblemModalVisible(true);
    return;
  };

  const mapProblems = (problemData: Problem[]) => {
    if (problemData.length == 0) {
      return <h2 style={{position: "absolute", top: "10vh"}}>Soutěž právě neprobíhá</h2>
    }
    return problemData.map((problem) => {
      return (
        <TreeNode
          problem={problem}
          state={problem.active}
          key={"problem" + problem.id}
          onClick={() => {
            treeNodeOnClick(problem);
          }}
        />
      );
    });
  };

  const mapLines = (problemData: Problem[]) => {
    let lines: any[] = [];
    problemData.map((problem) => {
      if (!problem.childrenIds) {
        return <></>;
      }
      let i = 0;
      return problem.childrenIds.map((childId) => {
        i += 1;
        const child = problemData[childId];
        lines.push(
          <Line
            x1={problem.xPos + 50 + "vw"}
            y1={problem.yPos + "vh"}
            x2={child.xPos + 50 + "vw"}
            y2={child.yPos + "vh"}
            key={problem.id + ":" + i}
          />
        );
        return <></>;
      });
    });
    return lines;
  };

  return (
    <>
      <div style={{ marginLeft: "10vw", marginTop: "2vh" }}>
        <div className="centerAlign treeContainer" style={{ width: "80vw" }}>
          <div style={{ position: "relative" }}>
            <svg
              width="100vw"
              height="150vh"
              style={{ transform: "translate(0vw,2vh)", overflow: "hidden" }}
            >
              {problems && mapLines(problems)}
            </svg>
          </div>
          <div style={{position: "absolute", left: "2vw", top: "2vh", fontSize: "1.7vw"}}>
            <Link to="/answers">Historie odpovědí</Link>
            </div>
          {problems && mapProblems(problems)}
        </div>
      </div>
      {problemModalVisible && (
        <ProblemModal
          problem={selectedProblem}
          setVisible={setProblemModalVisible}
        />
      )}
    </>
  );
}

export default ProblemTree;

import Cookies from "universal-cookie";

import "./styles/main.css";
import { useState, useEffect} from "react";
import { BrowserRouter } from "react-router-dom";

import GamePage from "./pages/GamePage";
import LoginPage from "./pages/LoginPage";

function App() {
  
  const cookies = new Cookies();

  let [identifier, setIdentifier] = useState(undefined);

  const cookieSetter = (token: string) => {cookies.set("identifier", token, { path: "/", maxAge: 16200 });}

  useEffect(() => {
    setIdentifier(cookies.get("identifier"))
  },[])
  

  return (
    <BrowserRouter>
      {identifier ? <GamePage token={identifier} /> : <LoginPage loginSetter={setIdentifier} cookieSetter={cookieSetter}/>}
    </BrowserRouter>
  );
}

export default App;

//const url = "https://vast-watch-412319.ey.r.appspot.com/";
//const url = "http://localhost:8080/"
const url = "https://omega-post-416123.ey.r.appspot.com/"

const imgurClientId = "eb72c9e08e7ed91"


import Cookies from "universal-cookie";

const cookies = new Cookies();

export const checkToken = (token,setData) => {
  fetch(url + "acceptData/checkToken", {
    method: "POST",
    body: JSON.stringify({
      token: token
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (!json.confirmed) {
        cookies.set("identifier", null, { path: "/", maxAge: 16200 })
        window.location.reload()
        return
      } else {
        setData(json.data)
      }
    });
}

export const checkAnswer = (problemId, input, token, setVisible, setMsg, setWaiting) => {
  if (input.length > 25) {
    setMsg("Zadaná odpověď je moc dlouhá");
    return
  }

  fetch(url + "acceptData/checkAnswer", {
    method: "POST",
    body: JSON.stringify({
      problemId: problemId,
      answer: input,
      token: token,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (!json.result) {
        setMsg(json.msg);
      } else {
        setVisible(false);
        window.location.reload(false);
      }
      setWaiting(false)
    });
};


export const login = async (user, pass, setLoggedIn, setCookie, setMsg, setWaiting) => {
  fetch(url + "acceptData/login", {
    method: "POST",
    body: JSON.stringify({
      teamName: user,
      password: pass,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.msg !== "Success") {
        setMsg(json.msg);
      } else {
        setCookie(json.token);
        setLoggedIn(json.token);
      }
      setWaiting(false)
      return
    });
};


export const getProblems = (token, setProblems, retry) => {
  fetch(url + "getData/problems", {
    method: "POST",
    body: JSON.stringify({
      token: token,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (!data.problems) {
        localStorage.setItem("problems", JSON.stringify([]));
        setProblems([]);
        //setTimeout(getProblems(token,setProblems, false), 350)
      } /*else if (data.problems && !retry) {
        setTimeout(getProblems(token,setProblems, true), 850)
      }*/
      else {
        localStorage.setItem("problems", JSON.stringify(data.problems));
        setProblems(data.problems);
      }
      return
    });
};


export const getTeams = (setTeams) => {
  fetch(url + "getData/teamsFull")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (!data.teams) {
        setTeams([])
      } else {
        setTeams(data.teams)
      }
      
    });
};


export const getAchievements = (setAchievements) => {
  fetch(url + "getData/achievements")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setAchievements(data.achievements)
      return
    });
}


export const getTeamAchievements = (setTeamAchievements) => {
  fetch(url + "getData/teamAchievements")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setTeamAchievements(data.teamAchievements)
      return
    });
}

export const sendChatMsg = (msg,token,setMsg) => {
  if (msg.length >= 200) {
    setMsg("Zpráva může být maximálně 200 znaků dlouhá")
    return
  }
  fetch(url + "acceptData/sendChatMsg", {
    method: "POST",
    body: JSON.stringify({
      token: token,
      msg: msg,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(msg)
      if (json.msg !== "Success") {
        setMsg(json.msg);
      } else {
        setMsg("Zpráva odeslána. Každá zpráva je před zveřejněním zkontrolována našimi moderátory")
      }
      return
    });
}

export const getChatMsgs = (setMsgs) => {
  fetch(url + "getData/chatMsgs")
  .then((res) => {
    return res.json();
  })
  .then((data) => {
    setMsgs(data.data)
    return
  });
}



/* SETTINGS*/ 
/*export const uploadPfp = (pfp) => {
  fetch("https://api.imgur.com/3/image", {
    method: "POST",
    body: JSON.stringify({
      type: "image",
      image: "https://storage.googleapis.com/oceanwide_web/media-dynamic/cache/widen_1600/media/default/0001/07/51c1ef50e9b38e89fc447b265b4f8ed4d705d093.jpeg",
      title: "pfphha",
      description: "hm?"
    }),
    headers: {
      "Authorization": `Client-ID ${imgurClientId}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json)
      return
    });
}*/

export const sendTeamDetails = (data, token, setMsg) => {
  fetch(url + "acceptData/changeTeamDetails", {
    method: "POST",
    body: JSON.stringify({
      token: token,
      motto: data.motto,
      email: data.email,
      pfp: data.pfp
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.msg !== "Success") {
        setMsg(json.msg);
      } else {
        setMsg("Změny odeslány. Musí být nejdříve potvrzeny administrátory")
      }
      return
    });
}

export const getAnswers = (token, setAnswers) => {
  fetch(url + "getData/getSubmittedAnswers", {
    method: "POST",
    body: JSON.stringify({
      token: token,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      setAnswers(json.data)
      return
    });
}
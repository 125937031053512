import { useState } from "react";
import { sendChatMsg } from "../../scripts/dataHandler";
import MsgBlock from "../../components/chatPage/MsgBlock";

function ChatPage({token, chatMsgs}) {

    let [msg,setMsg] = useState("")
    let [content,setContent] = useState("")

    const msgCompare = (a,b) => {
        if (a.critical == b.critical) {
            if (a.time > b.time) {
                return -1
            }
            else {
                return 1
            }
        } else if (a.critical) {
            return -1
        } else {return 1}
    }

    const mapMsgs = (msgs) => {
        msgs = msgs.sort(msgCompare)
        return msgs.map((msg) => 
            <MsgBlock data={msg}/>
            )
    }

    return ( <div className="column" style={{minHeight: "100vh", width: "100vw"}}>
        <div className="column centerAlign" style={{ width: "100vw", marginTop: "10vh"}}>
            <div className="row centerAlign">
                <h2>Zpráva: </h2>
                <div contentEditable id="input" onInput={(e) => setContent(e.target.value)} style={{marginLeft: "2vw", width: "40vw", padding: "0.6vw", color: "black", backgroundColor: "white", border: "0.2vw solid black", borderRadius: "2vw"}}></div>
            </div>
            <button onClick={() => {sendChatMsg(document.getElementById("input").innerHTML,token,setMsg)}} style={{marginTop: "1vh"}}>Odeslat</button>
            <h3>{msg}</h3>
        </div>
        <div className="column centerAlign" style={{width: "100vw"}}>
            {mapMsgs(chatMsgs)}
            </div>
        <div>

        </div>
    </div> );
}

export default ChatPage;